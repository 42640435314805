import React from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import classnames from 'classnames'
// import navigationConfig from '../../../../../configs/navigationConfig'
import SideMenuGroup from './SideMenuGroup'
import { Badge } from 'reactstrap'
import { ChevronRight } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { history } from '../../../../../history'
import * as Icon from 'react-feather'
class SideMenuContent extends React.Component {
  constructor(props) {
    super(props)
    this.parentArr = []
    this.collapsedPath = null
    this.redirectUnauthorized = () => {
      history.push('/misc/not-authorized')
    }
  }
  state = {
    userType: null,
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    navigationConfig: [
      {
        id: 'dashboard',
        user: '1',
        title: this.props.t('Dashboard'),
    
        // type: "collapse",
        type: 'item',
        icon: <Icon.Home size={18} />,
        badge: 'warning',
        // badgeText: "2",
        permissions: ['admin', 'editor'],
        navLink: '/'
      },
      {
        id: 'eCommerceDash',
        user: '2',
        title: 'Dashboard',
        type: 'item',
        icon: <Icon.Home size={18} />,
        permissions: ['admin', 'editor'],
        navLink: '/ecommerce-dashboard'
      },
      {
        type: 'groupHeader',
        groupTitle: this.props.t('Services')
      },
      // -----------------------------------------
      {
        id: 'Student-Profile',
        user: '1',
        // title: 'Assign Parcels',
        title: this.props.t('slidebar_1'),
        type: 'item',
        icon: <Icon.User size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/school-profile'
      },
      {
        id: 'listView',
        user: '1',
    
        // title: 'Vendor',
        title: this.props.t('parent_text'),
        type: 'item',
        // icon: <Icon.Box size={18} />,
        icon: <Icon.Users size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/parents'
      },
      {
        id: 'Students',
        user: '1',
        type: 'collapse',
        // title: 'Riders',
        title: this.props.t('student_text'),
        // type: 'item',
        // icon: <Icon.Users size={22} />,
        icon: <Icon.UserPlus size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'stud-list',
            title: this.props.t('list_crumb'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/student/student-list',
            permissions: ['admin', 'editor']
          },
          {
            id: 'assign-bus',
            title: this.props.t('submenu_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/student/assign-bus',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'AssignParcels',
        user: '1',
    
        // title: 'Assign Parcels',
        title: this.props.t('assistant_text'),
        type: 'item',
        icon: <Icon.Briefcase size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/bus-assistant'
      },
      {
        id: 'drivers',
        user: '1',
        // title: 'Assign Parcels',
        title: this.props.t('slidebar_driver'),
        type: 'item',
        icon: <Icon.UserPlus size={18} />,
        badge: 'primary',
        navLink: '/data-list/drivers',
        // badgeText: "new",
        permissions: ['admin', 'editor']
      },
      {
        id: 'trip',
        user: '1',
        // title: 'Assign Parcels',
        title: this.props.t('slidebar_trip'),
        type: 'collapse',
        icon: <Icon.Settings size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'child-trip',
            title: this.props.t('radio_button_2'),
            type: 'item',
            icon: <Icon.User size={18} />,
            navLink: '/data-list/trip-setting/child',
            permissions: ['admin', 'editor']
          },
          {
            id: 'adult-trip',
            title: this.props.t('radio_button_1'),
            type: 'item',
            icon: <Icon.User size={18} />,
            navLink: '/data-list/trip-setting/adult',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'track',
        user: '1',
        // title: 'Assign Parcels',
        title: this.props.t('track_text'),
        type: 'item',
        icon: <Icon.Truck size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/track-bus/0'
      },
      {
        id: 'Parcels',
        user: '1',
    
        // title: 'Parcels',
        title: this.props.t('buses'),
        type: 'collapse',
        // icon: <Icon.CreditCard size={22} />,
        icon: <Icon.Truck size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'bus-list',
            title: this.props.t('bus_list_crumb'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/bus/bus-list',
            permissions: ['admin', 'editor']
          },
          {
            id: 'assign-assistant',
            title: this.props.t('crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/bus/assign-assistant',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'notification',
        user: '1',
    
        // title: 'Parcels',
        title: this.props.t('slidebar_9'),
        type: 'collapse',
        // icon: <Icon.CreditCard size={22} />,
        icon: <Icon.AlertCircle size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'parent-notifi',
            title: this.props.t('notif_crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/notification/parent-notifi',
            permissions: ['admin', 'editor']
          },
          {
            id: 'assistant-id',
            title: this.props.t('an_crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/notification/assistant-notifi',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'import',
        user: '1',
        title: this.props.t('bold_text'),
        type: 'collapse',
        icon: <Icon.DownloadCloud size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'parent-import',
            title: this.props.t('import_crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/import/import-parents-list',
            permissions: ['admin', 'editor']
          },
          {
            id: 'student-import',
            title: this.props.t('import_submenu_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/import/import-students-list',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'Support',
        user: '1',
    
        // title: 'Assign Parcels',
        title: this.props.t('support'),
        type: 'item',
        icon: <Icon.Headphones size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/Support'
      },
    
      //---------------------------->Super admin
      {
        id: 'school-assistant',
        user: '2',
        // title: 'Vendor',
        title: 'School Coordinator',
        type: 'item',
        // icon: <Icon.Box size={18} />,
        icon: <Icon.Clipboard size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/pages/super-admin/school-assistant'
      },
      // {
      //   id: 'school-list',
      //   user: '2',
      //   title: 'School List',
      //   type: 'item',
      //   icon: <Icon.Users size={18} />,
      //   badge: 'primary',
      //   permissions: ['admin', 'editor'],
      //   navLink: '/pages/super-admin/school-list'
      // },
      {
        id: 'support-ticket',
        user: '2',
        // title: 'Vendor',
        title: 'Support Ticket',
        type: 'item',
        // icon: <Icon.Box size={18} />,
        icon: <Icon.Clipboard size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/pages/super-admin/support-ticket'
      }
    ]
  }

  updateNavigationConfigs = () => {
    const { t } = this.props;
    const newNavigationConfigs = [
      {
        id: 'dashboard',
        user: '1',
        title: t('Dashboard'),
    
        // type: "collapse",
        type: 'item',
        icon: <Icon.Home size={18} />,
        badge: 'warning',
        // badgeText: "2",
        permissions: ['admin', 'editor'],
        navLink: '/'
      },
      {
        id: 'eCommerceDash',
        user: '2',
        title: 'Dashboard',
        type: 'item',
        icon: <Icon.Home size={18} />,
        permissions: ['admin', 'editor'],
        navLink: '/ecommerce-dashboard'
      },
      {
        type: 'groupHeader',
        groupTitle: t('text_services')
      },
      // -----------------------------------------
      {
        id: 'Student-Profile',
        user: '1',
        // title: 'Assign Parcels',
        title: t('slidebar_1'),
        type: 'item',
        icon: <Icon.User size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/school-profile'
      },
      {
        id: 'listView',
        user: '1',
    
        // title: 'Vendor',
        title: t('parent_text'),
        type: 'item',
        // icon: <Icon.Box size={18} />,
        icon: <Icon.Users size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/parents'
      },
      {
        id: 'Students',
        user: '1',
        type: 'collapse',
        // title: 'Riders',
        title: t('student_text'),
        // type: 'item',
        // icon: <Icon.Users size={22} />,
        icon: <Icon.UserPlus size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'stud-list',
            title: this.props.t('list_crumb'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/student/student-list',
            permissions: ['admin', 'editor']
          },
          {
            id: 'assign-bus',
            title: t('submenu_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/student/assign-bus',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'AssignParcels',
        user: '1',
    
        // title: 'Assign Parcels',
        title: t('assistant_text'),
        type: 'item',
        icon: <Icon.Briefcase size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/bus-assistant'
      },
      {
        id: 'drivers',
        user: '1',
        // title: 'Assign Parcels',
        title: t('slidebar_driver'),
        type: 'item',
        icon: <Icon.UserPlus size={18} />,
        badge: 'primary',
        // badgeText: "new",
        navLink: '/data-list/drivers',
        permissions: ['admin', 'editor']
      },
      {
        id: 'trip',
        user: '1',
        // title: 'Assign Parcels',
        title: t('slidebar_trip'),
        type: 'collapse',
        icon: <Icon.Settings size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'child-trip',
            title: t('radio_button_2'),
            type: 'item',
            icon: <Icon.User size={18} />,
            navLink: '/data-list/trip-setting/child',
            permissions: ['admin', 'editor']
          },
          {
            id: 'adult-trip',
            title: t('radio_button_1'),
            type: 'item',
            icon: <Icon.User size={18} />,
            navLink: '/data-list/trip-setting/adult',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'track',
        user: '1',
        // title: 'Assign Parcels',
        title: t('track_text'),
        type: 'item',
        icon: <Icon.Truck size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/track-bus/0'
      },
      {
        id: 'Parcels',
        user: '1',
    
        // title: 'Parcels',
        title: t('buses'),
        type: 'collapse',
        // icon: <Icon.CreditCard size={22} />,
        icon: <Icon.Truck size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'bus-list',
            title: t('bus_list_crumb'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/bus/bus-list',
            permissions: ['admin', 'editor']
          },
          {
            id: 'assign-assistant',
            title: t('crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/bus/assign-assistant',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'notification',
        user: '1',
    
        // title: 'Parcels',
        title: t('slidebar_9'),
        type: 'collapse',
        // icon: <Icon.CreditCard size={22} />,
        icon: <Icon.AlertCircle size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'parent-notifi',
            title: t('notif_crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/notification/parent-notifi',
            permissions: ['admin', 'editor']
          },
          {
            id: 'assistant-id',
            title: t('an_crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/notification/assistant-notifi',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'import',
        user: '1',
        title: t('CSV'),
        type: 'collapse',
        icon: <Icon.DownloadCloud size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        children: [
          {
            id: 'parent-import',
            title: t('import_crumb_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/import/import-parents-list',
            permissions: ['admin', 'editor']
          },
          {
            id: 'student-import',
            title: t('import_submenu_2'),
            type: 'item',
            icon: <Icon.Circle size={10} />,
            navLink: '/data-list/import/import-students-list',
            permissions: ['admin']
          }
        ]
      },
      {
        id: 'Support',
        user: '1',
    
        // title: 'Assign Parcels',
        title: t('support'),
        type: 'item',
        icon: <Icon.Headphones size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/data-list/Support'
      },
    
      //---------------------------->Super admin
      {
        id: 'school-assistant',
        user: '2',
        // title: 'Vendor',
        title: 'School Coordinator',
        type: 'item',
        // icon: <Icon.Box size={18} />,
        icon: <Icon.Clipboard size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/pages/super-admin/school-assistant'
      },
      // {
      //   id: 'school-list',
      //   user: '2',
      //   title: 'School List',
      //   type: 'item',
      //   icon: <Icon.Users size={18} />,
      //   badge: 'primary',
      //   permissions: ['admin', 'editor'],
      //   navLink: '/pages/super-admin/school-list'
      // },
      {
        id: 'support-ticket',
        user: '2',
        // title: 'Vendor',
        title: 'Support Ticket',
        type: 'item',
        // icon: <Icon.Box size={18} />,
        icon: <Icon.Clipboard size={18} />,
        badge: 'primary',
        // badgeText: "new",
        permissions: ['admin', 'editor'],
        navLink: '/pages/super-admin/support-ticket'
      }
    ]
    this.setState({ navigationConfig: newNavigationConfigs });
  }

  handleGroupClick = (id, parent = null, type = '') => {
    let open_group = this.state.activeGroups
    let active_group = this.state.currentActiveGroup
    let temp_arr = this.state.tempArr
    // Active Group to apply sidebar-group-active class
    if (type === 'item' && parent === null) {
      active_group = []
      temp_arr = []
    } else if (type === 'item' && parent !== null) {
      active_group = []
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length)
      } else {
        temp_arr = []
        temp_arr.push(parent)
      }
      active_group = temp_arr.slice(0)
    } else if (type === 'collapse' && parent === null) {
      temp_arr = []
      temp_arr.push(id)
    } else if (type === 'collapse' && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0)
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length)
      } else {
        temp_arr.push(id)
      }
    } else {
      temp_arr = []
    }

    if (type === 'collapse') {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1
        })
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj)
          })
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0)
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id)
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1)
      }
    }
    if (type === 'item') {
      open_group = active_group.slice(0)
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group
    })
  }

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false
    })
  }

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : [])
    const tabId = localStorage.getItem('tabId')
    this.setState({ userType: tabId })
    // console.log(tabId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.t !== prevProps.t) {
      this.updateNavigationConfigs();
      console.log('updated')
    }
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths)
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      )
    }
  }

  render() {
    // Loop over sidebar items
    // eslint-disable-next-line
    console.log(this.state.navigationConfig)

    const menuItems = this.state.navigationConfig.map((item) => {
      const CustomAnchorTag = item.type === 'external-link' ? `a` : Link
      // checks if item has groupheader
      if (item.type === 'groupHeader') {
        return (
          <li
            className='navigation-header'
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        )
      }

      let renderItem = (
        <li
          className={classnames('nav-item', {
            'has-sub': item.type === 'collapse',
            open: this.state.activeGroups.includes(item.id),
            'sidebar-group-active': this.state.currentActiveGroup.includes(
              item.id
            ),
            hover: this.props.hoverIndex === item.id,
            active:
              (this.props.activeItemState === item.navLink &&
                item.type === 'item') ||
              (item.parentOf &&
                item.parentOf.includes(this.props.activeItemState)),
            disabled: item.disabled
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation()
            if (item.type === 'item') {
              this.props.handleActiveItem(item.navLink)
              this.handleGroupClick(item.id, null, item.type)
              if (this.props.deviceWidth <= 1200 && item.type === 'item') {
                this.props.toggleMenu()
              }
            } else {
              this.handleGroupClick(item.id, null, item.type)
            }
          }}
        >
          {/* {console.log(item.user)}
          {console.log()} */}
          {item.user === this.state.userType ? (
            <CustomAnchorTag
              to={
                item.filterBase
                  ? item.filterBase
                  : item.navLink && item.type === 'item'
                    ? item.navLink
                    : ''
              }
              href={item.type === 'external-link' ? item.navLink : ''}
              className={`d-flex ${item.badgeText
                ? 'justify-content-between'
                : 'justify-content-start'
                }`}
              onMouseEnter={() => {
                this.props.handleSidebarMouseEnter(item.id)
              }}
              onMouseLeave={() => {
                this.props.handleSidebarMouseEnter(item.id)
              }}
              key={item.id}
              onClick={(e) => {
                return item.type === 'collapse' ? e.preventDefault() : ''
              }}
              target={item.newTab ? '_blank' : undefined}
            >
              <div className='menu-text'>
                {item.icon}
                <span className='menu-item menu-title'>
                  <FormattedMessage id={item.title} />
                </span>
              </div>

              {item.badge ? (
                <div className='menu-badge'>
                  <Badge color={item.badge} className='mr-1' pill>
                    {item.badgeText}
                  </Badge>
                </div>
              ) : (
                ''
              )}
              {item.type === 'collapse' ? (
                <ChevronRight className='menu-toggle-icon' size={13} />
              ) : (
                ''
              )}
            </CustomAnchorTag>
          ) : (
            ''
          )}

          {item.type === 'collapse' ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={this.handleGroupClick}
              activeGroup={this.state.activeGroups}
              handleActiveItem={this.props.handleActiveItem}
              activeItemState={this.props.activeItemState}
              handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
              activePath={this.props.activePath}
              hoverIndex={this.props.hoverIndex}
              initRender={this.initRender}
              parentArr={this.parentArr}
              triggerActive={undefined}
              currentActiveGroup={this.state.currentActiveGroup}
              permission={this.props.permission}
              currentUser={this.props.currentUser}
              redirectUnauthorized={this.redirectUnauthorized}
              collapsedMenuPaths={this.props.collapsedMenuPaths}
              toggleMenu={this.props.toggleMenu}
              deviceWidth={this.props.deviceWidth}
            />
          ) : (
            ''
          )}
        </li>
      )

      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        this.collapsedPath = item.navLink
        this.props.collapsedMenuPaths(item.navLink)
      }

      if (
        item.type === 'collapse' ||
        item.type === 'external-link' ||
        (item.type === 'item' &&
          item.permissions &&
          item.permissions.includes(this.props.currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem
      } else if (
        item.type === 'item' &&
        item.navLink === this.props.activePath &&
        !item.permissions.includes(this.props.currentUser)
      ) {
        return this.redirectUnauthorized()
      }
    })
    return <React.Fragment>{menuItems}</React.Fragment>
  }
}
export default withTranslation()(SideMenuContent)
