import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './LanguageSwitcher.css'
const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const handleLanguageChange = (e) => {
        const newLang = e.target.value;
        console.log(newLang, "kdj")
        i18n.changeLanguage(newLang);
        localStorage.setItem('lang', newLang);
    };
    useEffect(() => {
        const initialLang = localStorage.getItem('lang') || 'en';
        i18n.changeLanguage(initialLang)
        // const savedLang = localStorage.getItem('lang');
        // if (savedLang) {
        //     i18n.changeLanguage(savedLang);
        // }
    }, []);
    return (
        <select class="language-select" value={i18n.language} onChange={handleLanguageChange}>
            <option value="en">English</option>
            <option value="ar">عربی</option>
        </select>
    );
};

export default LanguageSwitcher;