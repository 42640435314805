import axios from 'axios'
// import { PermissionsAndroid } from 'react-native'
// import Geocoder from 'react-native-geocoding'
// import Geolocation from 'react-native-geolocation-service'
// const url = 'https://7tracking.com/api/api.php';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export async function dataGet({ body }) {
  return await axios
    .post('https://locatestudent.com/api/api.php', body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
}

export const showToast = (type, message) => {
  if (type === 'error') {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    })
  } else {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    })
  }
}

// export const permissionAndroidLocation =  async () => {
//   if (Platform.OS === 'android') {
//       try {
//           const granted = await PermissionsAndroid.request(
//               PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
//           );
//           if (granted === PermissionsAndroid.RESULTS.GRANTED) {
//              let me = await getCurrentLocation()
//              console.log('chkkkk',me)
//           } else {
//               console.log("Camera permission denied");
//           }
//       } catch (err) {
//           console.warn(err);
//       }
//   }else{
//     await getCurrentLocation();

//   }
// }

// export const getCurrentLocation = async () => {
//   //ios only
//   let values
//   Geolocation.requestAuthorization('always').then((res) => {
//     console.log(res)
//   })
//   await Geolocation.getCurrentPosition(
//     (position) => {
//       let lat = position.coords.latitude
//       let lng = position.coords.longitude
//       global.currentLat = lat
//       global.currentLng = lng
//       Geocoder.from(lat, lng)
//         .then((json) => {
//           // console.log(json)
//           // setAddress(json.results[0].address_components[0].long_name + ' '+json.results[0].address_components[1].long_name
//           // + ' '+json.results[0].address_components[2].long_name)
//           // setCity(json.results[1].address_components[3].long_name)
//           let address =
//             json.results[0].address_components[0].long_name +
//             ' ' +
//             json.results[0].address_components[1].long_name +
//             ' ' +
//             json.results[0].address_components[2].long_name
//           let city = json.results[1].address_components[3].long_name
//           values = { address: address, city: city, lat: lat, lng: lng }
//           //  let data =  passValues(values)
//           return values
//         })
//         .catch((error) => console.warn(error))
//     },
//     (error) => {
//       console.log(error.code, error.message)
//     },
//     { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
//   )
//   setTimeout(() => {
//     return values
//   }, 5000)
// }

const passValues = (data) => {
  console.log(data)
  return data
}
