import React from "react";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";
import LanguageSwitcher from "../../../LanguageSwitcher";

import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from "reactstrap";
import {
  logoutWithJWT,
  logoutWithFirebase,
} from "../../../redux/actions/auth/loginActions";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";
import { dataGet } from "../../../common/apiData";
import { useEffect } from "react";
import { useState } from "react";
import profileImg from "../../../assets/img/profile/user-uploads/user-13.jpg";

const UserName = (props) => {
  let username = "";
  if (
    props.userdata == null ||
    props.userdata == "" ||
    props.userdata == undefined
  ) {
    username = "John Doe";
  } else {
    username = props.userdata;
  }
  return username;
};
const ThemeNavbar = (props) => {
  const { user } = useAuth0();
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const [userImg, setUserImg] = useState();
  const [userName, setUseName] = useState();
  const [checkNoti, setCheckNoti] = useState();
  const [noti, setNoti] = useState();

  useEffect(() => {
    console.log(userImg);
    console.log(profileImg);
    const use_id = localStorage.getItem("user_id");
    let user_type;
    let chktab = localStorage.getItem("tabId");
    if (chktab == 2) {
      user_type = 1;
    } else {
      user_type = 0;
    }
    const body = new FormData();
    body.append("type", "get_img");
    body.append("user_type", user_type);
    body.append("id", use_id);
    dataGet({ body })
      .then(async (res) => {
        console.log(res);
        if (res["data"] != undefined) {
          let image;
          image = res["data"];
          console.log(res["data"]);
          setUserImg(image);
          setUseName(res.name);

          console.log(res.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
            <div className="d-flex align-items-center gap-2">
                <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              <UncontrolledDropdown className="dropdown-user nav-item">
                <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                  <LanguageSwitcher />
                </DropdownToggle>
              </UncontrolledDropdown>
            </div>
            
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">LS</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={userName} {...props} />}
                userImg={
                  userImg == undefined
                    ? profileImg
                    : userImg == "https://locatestudent.com/api/images/"
                      ? profileImg
                      : userImg
                }
                loggedInWith={
                  props.user !== undefined &&
                    props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps, {
  logoutWithJWT,
  logoutWithFirebase,
  useAuth0,
})(ThemeNavbar);
