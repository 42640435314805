import React, { Fragment } from 'react'
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import axios from 'axios'
import * as Icon from 'react-feather'
import classnames from 'classnames'
import ReactCountryFlag from 'react-country-flag'
import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent'
import { useAuth0 } from '../../../authServices/auth0/auth0Service'
import { history } from '../../../history'
import { IntlContext } from '../../../utility/context/Internationalization'
import { dataGet } from '../../../common/apiData'

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = (props) => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu right>
      {/* <DropdownItem
        tag='a'
        href='#'
        onClick={(e) => handleNavigation(e, '/pages/profile')}
      >
        <Icon.User size={14} className='mr-50' />
        <span className='align-middle'>Edit Profile</span>
      </DropdownItem> */}

      {/* <DropdownItem divider /> */}
      <DropdownItem
        tag='a'
        href='/pages/login'
        onClick={(e) => {
          window.localStorage.removeItem('user_id')
          e.preventDefault()
          if (isAuthenticated) {
            return logout({
              returnTo:
                window.location.origin + process.env.REACT_APP_PUBLIC_PATH
            })
          } else {
            const provider = props.loggedInWith
            if (provider !== null) {
              if (provider === 'jwt') {
                return props.logoutWithJWT()
              }
              if (provider === 'firebase') {
                return props.logoutWithFirebase()
              }
            } else {
              history.push('/pages/login')
            }
          }
        }}
      >
        <Icon.Power size={14} className='mr-50' />
        <span className='align-middle'>Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  state = {
    notify: [],
    counter: '',
    notiCounter: '',
    school_id: ''
  }

  getNotiSeen() {
    const body = new FormData()
    body.append('type', 'noti_seen_school')
    body.append('school_id', this.state.school_id)
    dataGet({ body }).then(async (res) => {
      // console.log(res)
      this.setState({ counter: 0 })
    })
  }
  componentDidMount() {
    this.getData()
    setInterval(() => {
      this.getData(); // Now the "this" still references the component
    }, 5000);
  }

  getData() {
    const use_id = localStorage.getItem('user_id');
    const body = new FormData()
    body.append('type', 'get_coordinator_noti')
    body.append('id', use_id)
    dataGet({ body })
      .then(async (res) => {
        // console.log(res)
        if (res['data'] != undefined) {
          this.setState({ counter: res['check_trip'] })
          this.setState({ notify: res['data'] })
          this.setState({ school_id: res['data'][0].school_id })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  render() {
    return (
      <ul className='nav navbar-nav navbar-nav-user float-right'>
        <UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'
        >
          <DropdownToggle
            tag='a'
            className='nav-link nav-link-label'
            NavbarUser
            onClick={() => this.getNotiSeen()}
          >
            <Icon.Bell size={21} />

            {this.state.counter > 0 ? (
              <>
                <Badge pill color='primary' className='badge-up'>
                  {this.state.counter}
                </Badge>
              </>
            ) : (
              ''
            )}
          </DropdownToggle>
          <DropdownMenu tag='ul' right className='dropdown-menu-media'>
            <li className='dropdown-menu-header'>
              <div className='dropdown-header mt-0'>
                <h3 className='text-white'>{this.state.notify.length} New</h3>
                <span className='notification-title'>App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className='media-list overflow-hidden position-relative'
              options={{
                wheelPropagation: false
              }}
            >
              {
                this.state.notify.map((val) => (


                  <div className='d-flex justify-content-between'>
                    <Media className='d-flex align-items-start'>
                      <Media left href='#'>
                        <Icon.PlusSquare
                          className='font-medium-5 primary'
                          size={21}
                        />
                      </Media>
                      <Media body>
                        <Media heading className='primary media-heading' tag='h6'>
                          Trip Finished
                        </Media>
                        <p className='notification-text'>Your bus ({val.bus.bus_no}) has finished the trip</p>
                      </Media>
                      <small>
                        <time
                          className='media-meta'
                          dateTime='2015-06-11T18:29:20+08:00'
                        >
                          {val.timestamp}
                        </time>
                      </small>
                    </Media>
                  </div>
                ))
              }
            </PerfectScrollbar>

            {/* <li className='dropdown-menu-footer'>
              <DropdownItem tag='a' className='p-1 text-center'>
                <span className='align-middle'>Read all notifications</span>
              </DropdownItem>
            </li> */}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
          <DropdownToggle tag='a' className='nav-link dropdown-user-link'>
            <div className='user-nav d-md-flex d-none'>
              <span className='user-name text-bold-600'>
                {this.props.userName}
              </span>
              <span className='user-status'>Available</span>
            </div>
            <span data-tour='user'>
              <img
                src={this.props.userImg}
                className='round'
                height='40'
                width='40'
                alt='avatar'
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
        <NavItem>
        </NavItem>
    


      </ul>
    )
  }
}
export default NavbarUser
